import { format, startOfWeek } from "date-fns";
import uuid from "react-uuid";

export const localStorageDefault = (objectiveCurrentDate: string) => {
  return {
    id: uuid(),
    currentDate: objectiveCurrentDate,
    lastPlayed: "",
    currentStreak: 0,
    maxStreak: 0,
    scoreChart: {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      loss: 0,
    },
    todayGuesses: [],
    playedToday: false,
    firstPlay: true,
    weekly50: {
      weekStarting: objectiveCurrentDate
        ? format(startOfWeek(new Date(objectiveCurrentDate)), "MM/dd/yyyy")
        : "",
      scores: [],
    },
  };
};
