export const contextDefaults = {
  productDetails: {
    productId: "",
    brand: "",
    description: "",
    price: 0,
    url: "",
    images: [],
    gender: "",
    source: "",
    date: "",
    gameNum: 0,
  },
  showWeekly50Modal: false,
  changeShowWeekly50Modal: () => {},
  showArchivedModal: false,
  changeShowArchivedModal: () => {},
  currentArchivedProductResults: [],
  changeCurrentArchivedProductResults: () => {},
  changeProductDetails: () => {},
  currentFocusedImage: 0,
  changeCurrentFocusedImage: () => {},
  darkMode: true,
  changeDarkMode: () => [],
  fullTimezoneDate: "",
  changeFullTimezoneDate: () => {},
  objectiveCurrentDate: "",
  changeObjectiveCurrentDate: () => {},
  currentlyPlayingDate: "",
  changeCurrentlyPlayingDate: () => {},
  gameStatistics: {
    id: "",
    currentDate: "",
    lastPlayed: "",
    currentStreak: 0,
    maxStreak: 0,
    scoreChart: {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      loss: 0,
    },
    todayGuesses: [],
    playedToday: false,
    firstPlay: true,
    weekly50: {
      weekStarting: "",
      scores: [],
    },
  },
  changeGameStatistics: () => {},
  showHowToPlayModal: false,
  changeShowHowToPlayModal: () => {},
  showStatisticsModal: false,
  changeShowStatisticsModal: () => {},
  allGuesses: [],
  changeAllGuesses: () => {},
};
