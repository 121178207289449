import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Calendar from "react-calendar";
import {
  CgChevronLeftO,
  CgChevronDoubleLeftO,
  CgChevronRightO,
  CgChevronDoubleRightO,
} from "react-icons/cg";
import { AiFillStar } from "react-icons/ai";
import { parse } from "date-fns";
import { format } from "date-fns-tz";
import axios from "axios";
import { RemoveScroll } from "react-remove-scroll";
import { Product } from "../../interfaces/Product.interface";
import { AppContext } from "../../App";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { RxCross2 } from "react-icons/rx";
import { Breathing } from "react-shimmer";
import { waitImagesToLoad } from "../../waitImagesToLoad";
import { ProductPurchaseButton } from "../ProductDetails/ProductPurchaseButton";
import "./ArchivedModal.scss";
import "../HowToPlay/HowToPlay.scss";
import "../Header/Header.scss";
import "react-calendar/dist/Calendar.css";
import { convertStringHtmlToSpan } from "../ProductDetails/Hints";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transition: "transform 0.5s ease",
  },
};

export const ArchivedModal = () => {
  const {
    darkMode,
    productDetails,
    changeProductDetails,
    currentlyPlayingDate,
    changeCurrentlyPlayingDate,
    currentArchivedProductResults,
    changeCurrentArchivedProductResults,
    changeAllGuesses,
    currentFocusedImage,
    changeCurrentFocusedImage,
    objectiveCurrentDate,
    gameStatistics,
    showArchivedModal,
    changeShowArchivedModal,
  } = useContext(AppContext);

  const formatDate = (someDate: Date) => {
    return format(someDate, "MM/dd/yyyy", {
      timeZone: "America/New_York",
    });
  };

  const [calendarLimit, changeCalendarLimit] = useState<Date | undefined>(
    undefined
  );
  const [selectedDate, changeSelectedDate] = useState<Date | undefined>(
    undefined
  );
  const [resultsLoading, changeResultsLoading] = useState(false);
  const [currentArchiveDate, changeCurrentArchiveDate] = useState("");
  const [currentSelectedArchiveProduct, changeCurrentSelectedArchiveProduct] =
    useState<Product | undefined>();
  const [detailsShown, changeDetailsShown] = useState(false);
  const [archiveImageDoneLoading, changeArchiveImageDoneLoading] =
    useState(true);

  useEffect(() => {
    if (productDetails && objectiveCurrentDate && !selectedDate) {
      const parsedDate = parse(objectiveCurrentDate, "MM/dd/yyyy", new Date());
      changeCalendarLimit(parsedDate);
      changeSelectedDate(parsedDate);
      changeCurrentArchiveDate(formatDate(parsedDate));
      changeDetailsShown(false);
      changeCurrentArchiveDate(objectiveCurrentDate);
      changeCurrentArchivedProductResults([productDetails]);
      changeCurrentSelectedArchiveProduct(productDetails);
    }
  }, [
    objectiveCurrentDate,
    selectedDate,
    productDetails,
    changeCurrentArchivedProductResults,
  ]);

  const handleCloseModal = () => {
    changeShowArchivedModal(false);
    changeDetailsShown(false);
  };

  const handlePlayButton = () => {
    if (currentArchiveDate === objectiveCurrentDate) {
      // Going back to play today's game after playing an archived game
      changeCurrentlyPlayingDate(objectiveCurrentDate);
      changeShowArchivedModal(false);
      changeAllGuesses(gameStatistics.todayGuesses);
      changeDetailsShown(false);
      changeProductDetails(currentSelectedArchiveProduct);
      if (currentFocusedImage !== 0) changeCurrentFocusedImage(0);
    } else {
      // Playing an archived game other than today's game
      if (currentArchiveDate !== currentlyPlayingDate) {
        changeCurrentlyPlayingDate(currentArchiveDate);
        changeShowArchivedModal(false);
        changeAllGuesses([]);
        changeDetailsShown(false);
        changeProductDetails(currentSelectedArchiveProduct);
        if (currentFocusedImage !== 0) changeCurrentFocusedImage(0);
      }
    }
  };

  useEffect(() => {
    const matchingDateProductsArr = currentArchivedProductResults?.filter(
      (product) => product.date === currentArchiveDate
    );
    if (
      (!matchingDateProductsArr || matchingDateProductsArr.length === 0) &&
      currentArchiveDate &&
      currentArchiveDate !== objectiveCurrentDate
    ) {
      const source = axios.CancelToken.source();

      const fetchData = async () => {
        changeArchiveImageDoneLoading(false);
        await axios
          .get(
            process.env.REACT_APP_NODE_ENV === "production"
              ? `${process.env.REACT_APP_PRODUCTION_SERVER}/archive`
              : "http://localhost:4000/archive",
            {
              params: { date: currentArchiveDate },
            }
          )
          .then((res) => res.data)
          .then(async (data) => {
            if (data && data[0]) {
              changeCurrentArchivedProductResults([
                ...(currentArchivedProductResults || []),
                ...data,
              ]);
              changeCurrentSelectedArchiveProduct(data[0]);
              if (data[0].images) {
                await waitImagesToLoad(data[0].images).then(() =>
                  changeArchiveImageDoneLoading(true)
                );
              }
            }
            changeResultsLoading(false);
          })
          .catch((e) => {
            changeResultsLoading(false);
            changeArchiveImageDoneLoading(true);
            console.error(e);
          });
      };

      changeResultsLoading(true);
      changeDetailsShown(false);
      fetchData();

      return () => source.cancel();
    } else {
      if (matchingDateProductsArr && matchingDateProductsArr[0]) {
        changeCurrentSelectedArchiveProduct(matchingDateProductsArr[0]);
      }
    }
  }, [
    objectiveCurrentDate,
    currentArchiveDate,
    currentArchivedProductResults,
    changeCurrentArchivedProductResults,
    productDetails,
  ]);

  const handleDateChange = (value: Value) => {
    changeSelectedDate(value as Date);
    if (detailsShown) changeDetailsShown(false);
  };

  return (
    <RemoveScroll enabled={showArchivedModal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="Archive Modal"
        className={`modal_contents archive_modal_scroll_container how_to_play_modal ${
          showArchivedModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showArchivedModal && {
            overlay: {
              background: darkMode
                ? "rgba(0, 0, 0, 0.85)"
                : customModalStyles.overlay.background,
              transform: "translateY(100%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2 className="archived_game_title">PLAY AN ARCHIVED GAME</h2>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <p className="archive_prompt">
          Select a past Apparle game by picking an available date from the
          calendar.
        </p>
        {objectiveCurrentDate && calendarLimit && selectedDate && (
          <Calendar
            className={darkMode ? "dark" : ""}
            onChange={(value: Value) => handleDateChange(value)}
            value={selectedDate}
            calendarType={"US"}
            defaultView={"month"}
            minDetail={"year"}
            defaultValue={calendarLimit}
            maxDate={calendarLimit}
            minDate={new Date(2023, 3, 8)}
            prevLabel={
              <CgChevronLeftO size={25} color={darkMode ? "#fff" : "#000"} />
            }
            prev2Label={
              <CgChevronDoubleLeftO
                size={25}
                color={darkMode ? "#fff" : "#000"}
              />
            }
            nextLabel={
              <CgChevronRightO size={25} color={darkMode ? "#fff" : "#000"} />
            }
            next2Label={
              <CgChevronDoubleRightO
                size={25}
                color={darkMode ? "#fff" : "#000"}
              />
            }
            tileContent={({ activeStartDate, date, view }) => {
              return calendarLimit &&
                formatDate(date) === formatDate(calendarLimit) ? (
                <AiFillStar
                  size={25}
                  className={`current_date_star ${darkMode ? "dark" : ""}`}
                />
              ) : null;
            }}
            onClickDay={(value) => changeCurrentArchiveDate(formatDate(value))}
            showNeighboringMonth={false}
          />
        )}
        <div className="archive_results_container">
          <div className={`archive_date_container ${darkMode ? "dark" : ""}`}>
            <p>Selected Date:</p>
            <h3>{currentArchiveDate}</h3>
          </div>
          <div className="archive_date_products_container">
            <div className="archive_product_preview">
              <div
                className={`archive_product_image_container ${
                  detailsShown ? "details_shown" : ""
                } ${darkMode ? "dark" : ""}`}
              >
                {resultsLoading || !archiveImageDoneLoading ? (
                  <Breathing
                    className="loading_shimmer_hint_title"
                    width={100}
                    height={100}
                  />
                ) : currentSelectedArchiveProduct ? (
                  <img
                    src={currentSelectedArchiveProduct.images[0]}
                    alt={"Archived product"}
                  />
                ) : (
                  <></>
                )}
              </div>
              {currentSelectedArchiveProduct ? (
                <div
                  className={`archive_product_preview_details ${
                    detailsShown ? "details_shown" : ""
                  }`}
                >
                  <div className="archive_product_left_details">
                    <div className="preview_top_details">
                      <h3>{currentSelectedArchiveProduct.brand}</h3>
                      <p>
                        {convertStringHtmlToSpan(
                          (currentSelectedArchiveProduct.description
                            ?.charAt(0)
                            .toUpperCase() || "") +
                            (currentSelectedArchiveProduct.description?.slice(
                              1
                            ) || "")
                        )}
                      </p>
                    </div>
                    <p className="preview_price">
                      $
                      {currentSelectedArchiveProduct.price.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="archived_buttons_container bottom_buttons_container">
            <button
              className={`share_button archived_play_button ${
                resultsLoading ||
                (!productDetails?.weekStarting &&
                  currentArchiveDate === currentlyPlayingDate)
                  ? "disabled"
                  : ""
              }`}
              onClick={handlePlayButton}
            >
              {resultsLoading
                ? "PLAY GAME"
                : `PLAY ${
                    currentArchiveDate === objectiveCurrentDate
                      ? "TODAY'S GAME"
                      : `GAME #${currentSelectedArchiveProduct?.gameNum}`
                  }`}
            </button>
            {currentArchiveDate !== currentlyPlayingDate &&
            currentArchiveDate !== objectiveCurrentDate ? (
              <div className="product_reveal_button_container">
                <p
                  className={`spoiler_warning ${detailsShown ? "open" : ""} ${
                    darkMode ? "dark" : ""
                  }`}
                >
                  Spoilers ahead!
                </p>
                {detailsShown ? (
                  <ProductPurchaseButton
                    url={currentSelectedArchiveProduct?.url || ""}
                    source={currentSelectedArchiveProduct?.source || ""}
                    className="archive_product_link"
                  />
                ) : (
                  <>
                    <button
                      className={`archived_play_button reveal_price_button ${
                        darkMode ? "dark" : ""
                      } ${resultsLoading ? "disabled" : ""}`}
                      onClick={() => changeDetailsShown(!detailsShown)}
                    >
                      REVEAL PRICE
                    </button>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </RemoveScroll>
  );
};
