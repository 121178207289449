import { useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import KofiButton from "kofi-button";
import { AppContext } from "../../../App";
import { BiHeart } from "react-icons/bi";
import { TwitterFollowButton } from "./TwitterFollowButton";
import Switch from "react-switch";
import { BurgerProps } from "../../../interfaces/BurgerProps.interface";
import { HiOutlineMenu } from "react-icons/hi";
import { GrContact } from "react-icons/gr";
import { BsCalendar4Week } from "react-icons/bs";
import { RxCounterClockwiseClock } from "react-icons/rx";
import "./Burger.scss";
import "../Header.scss";

export const Burger = ({
  burgerMenuOpen,
  changeBurgerMenuOpen,
  changeSupportModal,
  changeShowContactModal,
}: BurgerProps) => {
  const {
    darkMode,
    changeDarkMode,
    changeShowWeekly50Modal,
    changeShowArchivedModal,
  } = useContext(AppContext);

  const handleChange = () => {
    changeDarkMode(!darkMode);
  };

  const handleDismissModal = () => changeBurgerMenuOpen(false);

  return (
    <Menu
      isOpen={burgerMenuOpen}
      onOpen={() => changeBurgerMenuOpen(true)}
      onClose={handleDismissModal}
      disableAutoFocus
      customBurgerIcon={
        <HiOutlineMenu
          className="burger_icon"
          color={darkMode ? "#fff" : "#000"}
          size={28}
        />
      }
      customCrossIcon={<AiOutlineClose color={darkMode ? "#fff" : "#000"} />}
    >
      <span className={`logo_container_burger ${darkMode ? "dark" : ""}`}>
        <h1 className="burger_logo">APPARLE</h1>
      </span>
      <div className={`burger_menu_item_container ${darkMode ? "dark" : ""}`}>
        <div className="menu-item">
          <Link to="/" onClick={handleDismissModal}>
            <AiOutlineHome
              className="home_icon"
              size={25}
              color={darkMode ? "#fff" : "000"}
            />
            <p>Home</p>
          </Link>
        </div>
        <div
          className="menu-item"
          onClick={() => {
            changeBurgerMenuOpen(false);
            changeShowWeekly50Modal(true);
          }}
        >
          <BsCalendar4Week size={20} color={darkMode ? "#fff" : "000"} />
          <p>Weekly 50</p>
        </div>
        <div
          className="menu-item"
          onClick={() => {
            changeBurgerMenuOpen(false);
            changeShowArchivedModal(true);
          }}
        >
          <RxCounterClockwiseClock
            size={23}
            color={darkMode ? "#fff" : "000"}
          />
          <p>Archive</p>
        </div>
        <div
          className="menu-item"
          onClick={() => {
            changeBurgerMenuOpen(false);
            changeShowContactModal(true);
          }}
        >
          <GrContact
            className="contact_icon"
            size={20}
            color={darkMode ? "#fff" : "000"}
          />
          <p>Contact</p>
        </div>
        <div
          className="menu-item"
          onClick={() => {
            changeBurgerMenuOpen(false);
            changeSupportModal(true);
          }}
        >
          <BiHeart
            className="contact_icon"
            size={25}
            color={darkMode ? "#fff" : "000"}
          />
          <p>Support</p>
        </div>
        <div className="menu-item set_dark_mode">
          <label>
            <Switch
              className="react-switch"
              onChange={handleChange}
              checked={darkMode}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </label>
          <p onClick={handleChange}>Night Mode</p>
        </div>
      </div>
      <div className="burger_support_buttons_container">
        <div className="kofi_button_container">
          <KofiButton
            color="#0a9396"
            title="Support on Ko-fi"
            kofiID="E1E3CFTNF"
          />
        </div>
        <TwitterFollowButton buttonText="@apparlegame" />
        <ul className={`policies_container ${darkMode ? "dark" : ""}`}>
          <li onClick={handleDismissModal}>
            <Link to={"/privacy"}>Privacy Policy</Link>
          </li>
          <li onClick={handleDismissModal}>
            <Link to={"/terms"}>Terms of Use</Link>
          </li>
        </ul>
      </div>
      <div
        className={`burger_other_projects_container ${darkMode ? "dark" : ""}`}
      >
        <p className="created_by">
          Created by{" "}
          <a
            href="https://amamenko.github.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            Avi Mamenko
          </a>
          .
        </p>
        <p className="main_statement">
          Check out the other game from the Apparle creator:
        </p>
        <div className="other_project_container">
          <h2>
            <a
              href="https://hollywoodle.vercel.app"
              rel="noopener noreferrer"
              target="_blank"
            >
              Hollywoodle
            </a>
          </h2>
          <p>
            Connect the two actors using as few movies and costars as possible!
          </p>
        </div>
      </div>
    </Menu>
  );
};
