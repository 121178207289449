import React, { useContext } from "react";
import { FaGlobeAmericas } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AppContext } from "../../App";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { ShareButtonTooltip } from "./ShareButtonTooltip";
import { BsCalendar4Week } from "react-icons/bs";

interface ShareButtonsProps {
  copiedShareStats: string;
  landingWinner?: boolean;
  changeShowGlobalStatsModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShareButtons = ({
  copiedShareStats,
  landingWinner,
  changeShowGlobalStatsModal,
}: ShareButtonsProps) => {
  const {
    darkMode,
    showArchivedModal,
    changeShowArchivedModal,
    showWeekly50Modal,
    changeShowWeekly50Modal,
  } = useContext(AppContext);

  const handleShowGlobalStats = () => {
    if (typeof changeShowGlobalStatsModal === "function")
      changeShowGlobalStatsModal(true);
  };

  const toggleArchivedModal = () => changeShowArchivedModal(!showArchivedModal);

  const toggleWeekly50Modal = () => changeShowWeekly50Modal(!showWeekly50Modal);

  if (landingWinner) {
    return (
      <div className={`bottom_buttons_container ${darkMode ? "dark" : ""}`}>
        <button className="play_weekly_50_button" onClick={toggleWeekly50Modal}>
          <BsCalendar4Week size={20} /> PLAY WEEKLY 50
        </button>
        <button className="archived_games_button" onClick={toggleArchivedModal}>
          <RxCounterClockwiseClock size={20} /> ARCHIVED GAMES
        </button>
        <button className="global_stats_button" onClick={handleShowGlobalStats}>
          <FaGlobeAmericas size={20} />
          GLOBAL STATS
        </button>
      </div>
    );
  } else {
    return (
      <div className={`bottom_buttons_container ${darkMode ? "dark" : ""}`}>
        <ShareButtonTooltip copiedShareStats={copiedShareStats} />
        <a
          className="twitter_share_link"
          href={`https://twitter.com/intent/tweet?${
            copiedShareStats
              ? `&text=${encodeURIComponent(copiedShareStats.trim())}`
              : ""
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="twitter">
            <AiOutlineTwitter size={25} />
            TWEET
          </button>
        </a>
      </div>
    );
  }
};
