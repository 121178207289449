import { useContext } from "react";
import { AppContext } from "../../App";
import { Guess } from "../../interfaces/Guess.interface";

interface GuessesProps {
  allGuesses: Guess[];
}

export const Guesses = ({ allGuesses }: GuessesProps) => {
  const { darkMode } = useContext(AppContext);
  return (
    <div className="guesses_container">
      {allGuesses
        .sort((a, b) => b.index - a.index)
        .map((guess) => (
          <div
            className={`guess_row ${darkMode ? "dark" : ""}`}
            key={guess.index}
          >
            <p>Guess {guess.index + 1}</p>
            <p>
              ${Number(guess.value).toLocaleString()}{" "}
              {guess.correct ? "🏆" : guess.overUnder === "under" ? "⬆️" : "⬇️"}
            </p>
          </div>
        ))}
    </div>
  );
};
