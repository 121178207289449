import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { IoCaretDownCircleOutline } from "react-icons/io5";
import { Collapse } from "react-collapse";
import axios from "axios";
import { Weekly50Product } from "../../interfaces/Weekly50Product.interface";
import { Breathing } from "react-shimmer";
import { FaPlay } from "react-icons/fa";
import {
  capitalizeFirstLetter,
  convertStringHtmlToSpan,
} from "../ProductDetails/Hints";
import { ProductPurchaseButton } from "../ProductDetails/ProductPurchaseButton";
import ClipLoader from "react-spinners/ClipLoader";
import "./Weekly50.scss";
import "../Header/Header.scss";
import { waitImagesToLoad } from "../../waitImagesToLoad";

interface Weekly50RowProps {
  index: number;
  openedGameDetails: number;
  changeOpenedGameDetails: React.Dispatch<React.SetStateAction<number>>;
  currentLoadedWeekly50Products: Weekly50Product[];
  changeCurrentLoadedWeekly50Products: React.Dispatch<
    React.SetStateAction<Weekly50Product[]>
  >;
}

export const Weekly50Row = ({
  index,
  openedGameDetails,
  changeOpenedGameDetails,
  currentLoadedWeekly50Products,
  changeCurrentLoadedWeekly50Products,
}: Weekly50RowProps) => {
  const {
    objectiveCurrentDate,
    darkMode,
    changeProductDetails,
    changeCurrentlyPlayingDate,
    changeShowWeekly50Modal,
    changeAllGuesses,
    currentFocusedImage,
    changeCurrentFocusedImage,
    gameStatistics,
  } = useContext(AppContext);
  const [weekly50ProductLoading, changeWeekly50ProductLoading] =
    useState(false);
  const [currentWeekly50Product, changeCurrentWeekly50Product] = useState<
    Weekly50Product | undefined
  >(undefined);
  const [productDetailsLoading, changeProductDetailsLoading] = useState(false);
  const [productImagesDoneLoading, changeProductImagesDoneLoading] =
    useState(false);

  const allScores = gameStatistics?.weekly50?.scores;
  const foundScoreEl = allScores?.find((score) => score.index === index - 1);

  const handleToggleDetails = (index: number) => {
    if (openedGameDetails === index) {
      changeOpenedGameDetails(-1);
    } else {
      changeOpenedGameDetails(index);
    }
  };

  const resetGameStates = () => {
    changeCurrentlyPlayingDate(objectiveCurrentDate);
    changeOpenedGameDetails(-1);
    changeShowWeekly50Modal(false);
    changeAllGuesses([]);
    if (currentFocusedImage !== 0) changeCurrentFocusedImage(0);
  };

  const fetchProductData = useCallback(async () => {
    return await axios
      .get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_PRODUCTION_SERVER}/weekly50`
          : "http://localhost:4000/weekly50",
        {
          params: { index },
        }
      )
      .then((res) => res.data)
      .then(async (data) => {
        if (data) {
          changeCurrentLoadedWeekly50Products([
            ...currentLoadedWeekly50Products,
            data,
          ]);
          changeCurrentWeekly50Product(data);
          changeProductImagesDoneLoading(false);
          await waitImagesToLoad(data.images)
            .then(() => changeProductImagesDoneLoading(true))
            .catch((e) => {
              console.error(e);
              changeProductImagesDoneLoading(true);
            });
        }
        changeWeekly50ProductLoading(false);
        return data;
      })
      .catch((e) => {
        changeWeekly50ProductLoading(false);
        console.error(e);
      });
  }, [
    changeCurrentLoadedWeekly50Products,
    currentLoadedWeekly50Products,
    index,
  ]);

  const handlePlayButton = async () => {
    if (currentWeekly50Product) {
      resetGameStates();
      changeProductDetails(currentWeekly50Product);
    } else {
      changeProductDetailsLoading(true);
      const data = await fetchProductData();
      if (data) {
        resetGameStates();
        changeProductDetails(data);
      }
      changeProductDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (index === openedGameDetails && !currentWeekly50Product) {
      const matchingDateProductsArr = currentLoadedWeekly50Products?.filter(
        (product) => product.index === index - 1
      );
      if (!matchingDateProductsArr || matchingDateProductsArr.length === 0) {
        const source = axios.CancelToken.source();

        const fetchData = async () => {
          await fetchProductData();
        };

        changeWeekly50ProductLoading(true);
        fetchData();

        return () => source.cancel();
      } else {
        if (matchingDateProductsArr && matchingDateProductsArr[0]) {
          changeCurrentWeekly50Product(matchingDateProductsArr[0]);
        }
      }
    }
  }, [
    currentLoadedWeekly50Products,
    changeCurrentLoadedWeekly50Products,
    currentWeekly50Product,
    openedGameDetails,
    index,
    fetchProductData,
  ]);

  return (
    <>
      <div
        className={`weekly_50_row ${darkMode ? "dark" : ""} ${
          foundScoreEl ? (foundScoreEl.result === "loss" ? "loss" : "win") : ""
        }`}
      >
        <div className="left_row_container">
          <div className="game_num_container">
            <p>{index}</p>
          </div>
        </div>
        <button
          className={`show_game_details reveal_price_button ${
            darkMode ? "dark" : ""
          }`}
          onClick={() => handleToggleDetails(index)}
        >
          <p>VIEW GAME DETAILS</p>{" "}
          <IoCaretDownCircleOutline
            size={25}
            color={darkMode ? "#fff" : "#000"}
          />
        </button>
        <div
          className={`right_row_container ${
            !foundScoreEl ? "play_button" : ""
          } ${darkMode ? "dark" : ""}`}
          onClick={!foundScoreEl ? handlePlayButton : () => {}}
        >
          <div className="score_divider_column">
            {foundScoreEl ? (
              <>
                <p>
                  {foundScoreEl?.result === "loss"
                    ? "❌"
                    : foundScoreEl?.result || ""}
                </p>
                <p>6</p>
              </>
            ) : productDetailsLoading ? (
              <ClipLoader size={25} color={darkMode ? "#fff" : "#000"} />
            ) : (
              <FaPlay size={15} color={darkMode ? "#fff" : "#000"} />
            )}
          </div>
        </div>
      </div>
      <Collapse isOpened={openedGameDetails === index}>
        <div className={`weekly_50_game_details ${darkMode ? "dark" : ""}`}>
          <div
            className={`weekly_50_product_image_container ${
              darkMode ? "dark" : ""
            }`}
          >
            {weekly50ProductLoading || !productImagesDoneLoading ? (
              <Breathing
                className="loading_shimmer_hint_title"
                width={100}
                height={100}
              />
            ) : currentWeekly50Product ? (
              <img
                src={currentWeekly50Product.images[0]}
                alt={"Weekly 50 product"}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            className={`product_text_details_container ${
              darkMode ? "dark" : ""
            }`}
          >
            <div className="product_text_details_top_container">
              <h2 className={`weekly_50_brand ${foundScoreEl ? "" : "hidden"}`}>
                {foundScoreEl
                  ? convertStringHtmlToSpan(currentWeekly50Product?.brand)
                  : "XXXXX XXXXX"}
              </h2>
              <h3
                className={`weekly_50_description ${
                  foundScoreEl ? "" : "hidden"
                }`}
              >
                {foundScoreEl
                  ? convertStringHtmlToSpan(
                      capitalizeFirstLetter(
                        currentWeekly50Product?.description || ""
                      )
                    )
                  : "XX XXX XXXXX XXXX XX"}
              </h3>
            </div>
            <div className="product_text_details_bottom_container">
              <p className={`weekly_50_price ${foundScoreEl ? "" : "hidden"}`}>
                {foundScoreEl
                  ? `${`$${currentWeekly50Product?.price.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2 }
                    )}`}`
                  : "XXXXX"}
              </p>
            </div>
          </div>
        </div>
        <div className="weekly_50_buttons_container bottom_buttons_container">
          <button
            className={`weekly_50_play_button ${
              foundScoreEl ? "" : "no_purchase_button"
            } ${weekly50ProductLoading ? "disabled" : ""}`}
            onClick={handlePlayButton}
          >
            PLAY GAME #{index}
          </button>
          {foundScoreEl && (
            <ProductPurchaseButton
              url={currentWeekly50Product?.url || ""}
              source={currentWeekly50Product?.source || ""}
            />
          )}
        </div>
      </Collapse>
    </>
  );
};
