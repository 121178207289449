import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import KofiButton from "kofi-button";
import { RxCross2 } from "react-icons/rx";
import { useContext } from "react";
import { AppContext } from "../../App";
import "./Header.scss";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transitionDelay: "0.15s",
    transition: "transform 0.5s ease",
  },
};

interface SupportProps {
  supportModal: boolean;
  changeSupportModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Support = ({ supportModal, changeSupportModal }: SupportProps) => {
  const { darkMode } = useContext(AppContext);

  const handleCloseModal = () => {
    changeSupportModal(false);
  };

  return (
    <RemoveScroll enabled={supportModal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="Support Modal"
        className={`modal_contents support_modal ${
          supportModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!supportModal && {
            overlay: {
              transform: "translateY(150%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>SUPPORT APPARLE</h2>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <p>
          If you enjoy playing the game, please consider supporting Apparle.
        </p>
        <p>
          Apparle is free to play and always will be! Every donation helps keep
          the website up and running.
        </p>
        <p>Thank you for visiting the site and for your support.</p>
        <div className="kofi_button_container">
          <KofiButton
            color="#0a9396"
            title="Support the creator on Ko-fi"
            kofiID="E1E3CFTNF"
          />
        </div>
      </Modal>
    </RemoveScroll>
  );
};
