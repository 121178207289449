import React, { createContext, useCallback, useEffect, useState } from "react";
import { Product } from "./interfaces/Product.interface";
import { Guess } from "./interfaces/Guess.interface";
import { contextDefaults } from "./contextDefaults";
import { ContextProps } from "./interfaces/ContextProps.interface";
import { HowToPlay } from "./components/HowToPlay/HowToPlay";
import useLocalStorage from "use-local-storage";
import { differenceInDays, format, parse, startOfWeek } from "date-fns";
import uuid from "react-uuid";
import { localStorageDefault } from "./localStorageDefault";
import { Statistics } from "./components/Statistics/Statistics";
import { ApparleLocalStorage } from "./interfaces/ApparleLocalStorage.interface";
import { Main } from "./pages/Main";
import "./App.scss";

export const AppContext = createContext<ContextProps>(contextDefaults);

const App = () => {
  const [productDetails, changeProductDetails] = useState<Product>();
  const [allGuesses, changeAllGuesses] = useState<Guess[]>([]);
  const [gameInitialMount, changeGameInitialMount] = useState(false);
  const [currentFocusedImage, changeCurrentFocusedImage] = useState(0);

  // General app settings
  const [darkMode, changeDarkMode] = useState(true);
  const [showHowToPlayModal, changeShowHowToPlayModal] = useState(false);
  const [showStatisticsModal, changeShowStatisticsModal] = useState(false);

  // For use in statistics/game logic
  const [fullTimezoneDate, changeFullTimezoneDate] = useState("");
  const [objectiveCurrentDate, changeObjectiveCurrentDate] = useState("");

  // For use in archived games
  const [showArchivedModal, changeShowArchivedModal] = useState(false);
  const [currentArchivedProductResults, changeCurrentArchivedProductResults] =
    useState<Product[]>();
  const [currentlyPlayingDate, changeCurrentlyPlayingDate] = useState("");

  // For use in weekly 50
  const [showWeekly50Modal, changeShowWeekly50Modal] = useState(false);

  const [gameStatistics, changeGameStatistics] =
    useLocalStorage<ApparleLocalStorage>(
      "apparle-statistics",
      localStorageDefault(objectiveCurrentDate)
    );

  // Set game stats on mount
  useEffect(() => {
    if (!gameInitialMount && objectiveCurrentDate) {
      const objectiveStartOfWeek = format(
        startOfWeek(new Date(objectiveCurrentDate)),
        "MM/dd/yyyy"
      );
      changeGameInitialMount(true);
      if (gameStatistics.firstPlay) {
        changeShowHowToPlayModal(true);
        changeGameStatistics({
          ...gameStatistics,
          currentDate: objectiveCurrentDate,
          firstPlay: false,
          weekly50: {
            weekStarting: objectiveStartOfWeek,
            scores: [],
          },
        });
      } else {
        let resetStreak = false;
        if (objectiveCurrentDate !== gameStatistics.currentDate) {
          if (gameStatistics.lastPlayed) {
            const parsedCurrentdDate = parse(
              objectiveCurrentDate,
              "MM/dd/yyyy",
              new Date()
            );
            const parsedLastPlayed = parse(
              gameStatistics.lastPlayed,
              "MM/dd/yyyy",
              new Date()
            );

            const difference = differenceInDays(
              parsedCurrentdDate,
              parsedLastPlayed
            );
            if (difference >= 2) resetStreak = true;
          }
        }

        const playedToday = gameStatistics.lastPlayed === objectiveCurrentDate;
        if (gameStatistics.currentDate === objectiveCurrentDate) {
          changeAllGuesses(gameStatistics.todayGuesses);
        }
        changeGameStatistics({
          ...gameStatistics,
          ...{
            id: gameStatistics.id || uuid(),
            currentDate: objectiveCurrentDate,
            currentStreak: resetStreak ? 0 : gameStatistics.currentStreak,
            todayGuesses:
              gameStatistics.currentDate === objectiveCurrentDate
                ? gameStatistics.todayGuesses
                : [],
            playedToday,
            weekly50:
              gameStatistics.weekly50?.weekStarting === objectiveStartOfWeek
                ? gameStatistics.weekly50
                : {
                    weekStarting: objectiveStartOfWeek,
                    scores: [],
                  },
          },
        });
      }
    }
  }, [
    changeGameStatistics,
    gameStatistics,
    gameInitialMount,
    objectiveCurrentDate,
  ]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const handleLightChange = useCallback(
    (event: MediaQueryListEvent) => {
      if (event.matches) {
        if (!darkMode) changeDarkMode(true);
      } else {
        if (darkMode) changeDarkMode(false);
      }
    },
    [darkMode]
  );

  // Look for color preference on app mount
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      changeDarkMode(true);
    } else {
      changeDarkMode(false);
    }
  }, []);

  // Watch for light preference changes
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleLightChange);
  }, [handleLightChange]);

  return (
    <AppContext.Provider
      value={{
        productDetails,
        changeProductDetails,
        currentFocusedImage,
        changeCurrentFocusedImage,
        darkMode,
        changeDarkMode,
        currentArchivedProductResults,
        changeCurrentArchivedProductResults,
        fullTimezoneDate,
        changeFullTimezoneDate,
        objectiveCurrentDate,
        changeObjectiveCurrentDate,
        currentlyPlayingDate,
        changeCurrentlyPlayingDate,
        gameStatistics,
        changeGameStatistics,
        showHowToPlayModal,
        changeShowHowToPlayModal,
        showStatisticsModal,
        changeShowStatisticsModal,
        allGuesses,
        changeAllGuesses,
        showArchivedModal,
        changeShowArchivedModal,
        showWeekly50Modal,
        changeShowWeekly50Modal,
      }}
    >
      <div className={`app_container ${darkMode ? "dark" : ""}`}>
        <HowToPlay />
        <Statistics />
        <Main />
      </div>
    </AppContext.Provider>
  );
};

export default App;
