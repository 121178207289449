import React, { useContext, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { RiBarChart2Line } from "react-icons/ri";
import { Burger } from "./Burger/Burger";
import { AppContext } from "../../App";
import { Support } from "./Support";
import { Contact } from "./Contact/Contact";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { ArchivedModal } from "../Archive/ArchiveModal";
import { Weekly50 } from "../Weekly50/Weekly50";
import "./Header.scss";
import "../HowToPlay/HowToPlay.scss";

export const Header = () => {
  const [burgerMenuOpen, changeBurgerMenuOpen] = useState(false);
  const {
    showHowToPlayModal,
    changeShowHowToPlayModal,
    showStatisticsModal,
    changeShowStatisticsModal,
    darkMode,
    showArchivedModal,
    changeShowArchivedModal,
  } = useContext(AppContext);

  const [supportModal, changeSupportModal] = useState(false);
  const [showContactModal, changeShowContactModal] = useState(false);

  const handleQuestionClick = () => {
    if (showHowToPlayModal) {
      changeShowHowToPlayModal(false);
    } else {
      changeShowHowToPlayModal(true);
    }
  };

  const handleArchivedClick = () => {
    if (showArchivedModal) {
      changeShowArchivedModal(false);
    } else {
      changeShowArchivedModal(true);
    }
  };

  const handleStatisticsClick = () => {
    if (showStatisticsModal) {
      changeShowStatisticsModal(false);
    } else {
      changeShowStatisticsModal(true);
    }
  };
  return (
    <header className={`app_header ${darkMode ? "dark" : ""}`}>
      <div className={`inner_header_wrapper ${darkMode ? "dark" : ""}`}>
        <div className="left_side_header">
          <Burger
            burgerMenuOpen={burgerMenuOpen}
            changeBurgerMenuOpen={changeBurgerMenuOpen}
            changeSupportModal={changeSupportModal}
            changeShowContactModal={changeShowContactModal}
          />
          <AiOutlineQuestionCircle
            className="header_question_circle"
            color={darkMode ? "#fff" : "#000"}
            size={25}
            onClick={handleQuestionClick}
          />
        </div>
        <h1 className="header_logo">APPARLE</h1>
        <div className="right_side_header">
          <RxCounterClockwiseClock
            color={darkMode ? "#fff" : "#000"}
            size={23}
            onClick={handleArchivedClick}
          />
          <RiBarChart2Line
            color={darkMode ? "#fff" : "#000"}
            size={25}
            onClick={handleStatisticsClick}
          />
          <ArchivedModal />
          <Support
            supportModal={supportModal}
            changeSupportModal={changeSupportModal}
          />
          <Contact
            showContactModal={showContactModal}
            changeShowContactModal={changeShowContactModal}
          />
          <Weekly50 />
        </div>
      </div>
    </header>
  );
};
