import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { AppContext } from "../../App";
import { RxCross2 } from "react-icons/rx";
import { MdArrowBack } from "react-icons/md";
import { Weekly50Row } from "./Weekly50Row";
import { Weekly50Product } from "../../interfaces/Weekly50Product.interface";
import Countdown from "react-countdown";
import { addDays, startOfWeek } from "date-fns";
import "./Weekly50.scss";
import "../Header/Header.scss";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transition: "transform 0.5s ease",
  },
};

export const Weekly50 = () => {
  const {
    darkMode,
    showWeekly50Modal,
    changeShowWeekly50Modal,
    objectiveCurrentDate,
    fullTimezoneDate,
    gameStatistics,
    productDetails,
    changeCurrentlyPlayingDate,
    changeAllGuesses,
    currentFocusedImage,
    changeCurrentFocusedImage,
    changeProductDetails,
    currentArchivedProductResults,
  } = useContext(AppContext);
  const [openedGameDetails, changeOpenedGameDetails] = useState(-1);
  const [currentLoadedWeekly50Products, changeCurrentLoadedWeekly50Products] =
    useState<Weekly50Product[]>([]);
  const fiftyArr = useRef<number[]>([]);

  useEffect(() => {
    if (fiftyArr.current.length !== 50) {
      fiftyArr.current = Array.from(Array(51).keys()).slice(1);
    }
  }, []);

  const handleCloseModal = () => {
    changeShowWeekly50Modal(false);
  };

  const handleReturnToToday = () => {
    const allProductResults = currentArchivedProductResults;
    const todaysObjectiveProduct = allProductResults?.find(
      (product) => product.date === objectiveCurrentDate
    );
    if (todaysObjectiveProduct) {
      changeCurrentlyPlayingDate(objectiveCurrentDate);
      changeShowWeekly50Modal(false);
      changeAllGuesses(gameStatistics?.todayGuesses || []);
      changeOpenedGameDetails(-1);
      changeProductDetails(todaysObjectiveProduct);
      if (currentFocusedImage !== 0) changeCurrentFocusedImage(0);
    }
  };

  return (
    <RemoveScroll enabled={showWeekly50Modal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="Weekly 50 Modal"
        className={`modal_contents weekly_50_modal_scroll_container how_to_play_modal ${
          showWeekly50Modal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showWeekly50Modal && {
            overlay: {
              background: darkMode
                ? "rgba(0, 0, 0, 0.85)"
                : customModalStyles.overlay.background,
              transform: "translateY(100%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>APPARLE WEEKLY 50</h2>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        {objectiveCurrentDate && (
          <p className="weekly_50_week_of">
            Week of {gameStatistics?.weekly50?.weekStarting || ""} -{" "}
            {gameStatistics?.weekly50?.scores?.length || 0}/50 completed
          </p>
        )}
        <p>
          Can't get enough with just one product a day? Here are 50 more Apparle
          games for this week!
        </p>
        <div
          className={`weekly_50_countdown_timer_container ${
            darkMode ? "dark" : ""
          }`}
        >
          <p>Next Weekly 50 in</p>
          <Countdown
            className="weekly_50_countdown_timer"
            date={startOfWeek(addDays(new Date(objectiveCurrentDate), 7))}
            autoStart={true}
            daysInHours={false}
          />
          <div className="weekly_50_time_disclaimer">
            <p>
              at 12:00 AM Eastern Time (GMT-4)
              {!fullTimezoneDate.includes("12:00 AM") && " or"}
            </p>
            {!fullTimezoneDate.includes("12:00 AM") && (
              <>
                <p>{fullTimezoneDate}</p>
              </>
            )}
          </div>
        </div>
        {productDetails?.weekStarting && (
          <div className="weekly_50_buttons_container bottom_buttons_container">
            <button
              className="return_to_todays_game"
              onClick={handleReturnToToday}
            >
              <MdArrowBack size={20} />
              RETURN TO TODAY'S GAME
            </button>
          </div>
        )}
        <div className="archive_results_container">
          {fiftyArr.current.map((number) => {
            return (
              <React.Fragment key={number}>
                <Weekly50Row
                  index={number}
                  openedGameDetails={openedGameDetails}
                  changeOpenedGameDetails={changeOpenedGameDetails}
                  currentLoadedWeekly50Products={currentLoadedWeekly50Products}
                  changeCurrentLoadedWeekly50Products={
                    changeCurrentLoadedWeekly50Products
                  }
                />
              </React.Fragment>
            );
          })}
        </div>
      </Modal>
    </RemoveScroll>
  );
};
