import { GrTwitter } from "react-icons/gr";
import "./Burger.scss";

interface TwitterFollowButtonProps {
  buttonText: string;
  win?: boolean;
}

export const TwitterFollowButton = ({
  buttonText,
  win,
}: TwitterFollowButtonProps) => (
  <div className="winner_kofi_button">
    <div className="btn-container">
      <a
        className={`kofi-button twitter ${win ? "win" : ""}`}
        href="https://twitter.com/apparlegame"
        target="_blank"
        rel="noreferrer noopener"
        title="Follow Apparle's Twitter page @apparlegame"
      >
        <span className="kofitext">
          <GrTwitter className="kofiimg" size={18} />
          {buttonText}
        </span>
      </a>
    </div>
  </div>
);
