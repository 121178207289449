import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { AppContext } from "../../App";
import { CountdownTimer } from "../Countdown/Countdown";
import { GraphDistribution } from "./GraphDistribution";
import "../HowToPlay/HowToPlay.scss";
import "./Statistics.scss";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(1px)",
    zIndex: 99999,
    opacity: 1,
    transition: "transform 0.5s ease",
  },
};

export const Statistics = () => {
  const {
    fullTimezoneDate,
    gameStatistics,
    showStatisticsModal,
    changeShowStatisticsModal,
    darkMode,
  } = useContext(AppContext);
  const [allGamesPlayed, changeAllGamesPlayed] = useState(0);
  const [winPercent, changeWinPercent] = useState(0);

  Modal.setAppElement("#root");

  const handleCloseModal = () => changeShowStatisticsModal(false);

  useEffect(() => {
    if (showStatisticsModal) {
      const totalGamesPlayed =
        Object.values(gameStatistics?.scoreChart || {}).reduce(
          (a, b) => a + b,
          0
        ) || 0;
      if (totalGamesPlayed > 0) {
        const finalGamesPlayed = totalGamesPlayed;
        if (finalGamesPlayed) {
          changeAllGamesPlayed(finalGamesPlayed);
          if (gameStatistics?.scoreChart) {
            const allWins =
              (gameStatistics.scoreChart["1"] || 0) +
              (gameStatistics.scoreChart["2"] || 0) +
              (gameStatistics.scoreChart["3"] || 0) +
              (gameStatistics.scoreChart["4"] || 0) +
              (gameStatistics.scoreChart["5"] || 0) +
              (gameStatistics.scoreChart["6"] || 0);
            const percent = Math.round((allWins / finalGamesPlayed) * 100);
            changeWinPercent(percent);
          }
        }
      }
    }
  }, [gameStatistics, showStatisticsModal]);

  return (
    <RemoveScroll enabled={showStatisticsModal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="Statistics Modal"
        className={`modal_contents statistics_modal ${
          showStatisticsModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showStatisticsModal && {
            overlay: {
              background: darkMode
                ? "rgba(0, 0, 0, 0.85)"
                : customModalStyles.overlay.background,
              transform: "translateY(150%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>STATISTICS</h2>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <div className={`statistics_outer_container ${darkMode ? "dark" : ""}`}>
          <div className="statistics_inner_container">
            <h3>{allGamesPlayed}</h3>
            <div className="statistics_label">Played</div>
          </div>
          <div className="statistics_inner_container">
            <h3>{winPercent}</h3>
            <div className="statistics_label">Win %</div>
          </div>
          <div className="statistics_inner_container">
            <h3>{gameStatistics?.currentStreak || 0}</h3>
            <div className="statistics_label">Current Streak</div>
          </div>
          <div className="statistics_inner_container">
            <h3>{gameStatistics?.maxStreak || 0}</h3>
            <div className="statistics_label">Max Streak</div>
          </div>
        </div>
        <div className="graph_distribution_container">
          <h3>GUESS DISTRIBUTION</h3>
          {allGamesPlayed > 0 ? <GraphDistribution /> : <p>No data</p>}
        </div>
        <div className="game_countdown_timer">
          <p>
            Next Apparle in <CountdownTimer />
          </p>
          <div className="time_disclaimer">
            <p>at 12:00 AM Eastern Time (GMT-4)</p>
            {!fullTimezoneDate.includes("12:00 AM") && (
              <>
                <p>or {fullTimezoneDate}</p>
              </>
            )}
          </div>
        </div>
      </Modal>
    </RemoveScroll>
  );
};
