import { useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { AppContext } from "../../App";
import { ShareButtons } from "./ShareButtons";
import "../HowToPlay/HowToPlay.scss";
import "./Winner.scss";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transition: "transform 0.5s ease",
  },
};

interface AllGuessesModalProps {
  copiedShareStats: string;
  showAllGuessesModal: boolean;
  changeShowAllGuessesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AllGuessesModal = ({
  copiedShareStats,
  showAllGuessesModal,
  changeShowAllGuessesModal,
}: AllGuessesModalProps) => {
  const { objectiveCurrentDate, allGuesses, darkMode, productDetails } =
    useContext(AppContext);
  const handleCloseModal = () => {
    changeShowAllGuessesModal(false);
  };

  Modal.setAppElement("#root");

  return (
    <RemoveScroll enabled={showAllGuessesModal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="All Guesses Modal"
        className={`modal_contents all_guesses_modal ${
          showAllGuessesModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showAllGuessesModal && {
            overlay: {
              transform: "translateY(150%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>
          {productDetails?.weekStarting
            ? `WEEKLY 50 #${Number(productDetails?.index) + 1}`
            : productDetails?.date === objectiveCurrentDate
            ? "TODAY'S"
            : "ARCHIVED GAME"}{" "}
          GUESSES
        </h2>
        <h3>
          {productDetails?.gameNum
            ? `Apparle #${productDetails?.gameNum} - `
            : ""}
          {productDetails?.weekStarting
            ? `Week starting ${productDetails?.weekStarting}`
            : productDetails?.date}
        </h3>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <div
          className={`modal_guess_row descriptors ${darkMode ? "dark" : ""}`}
        >
          <p>Order</p>
          <p>Value</p>
          <p>Feedback</p>
          <p>% Off</p>
        </div>
        <div className="all_guesses_container">
          {allGuesses
            .sort((a, b) => a.index - b.index)
            .map((guess) => {
              return (
                <div
                  key={guess.index}
                  className={`modal_guess_row ${darkMode ? "dark" : ""}`}
                >
                  <p>#{guess.index + 1}</p>
                  <p>${guess.value}</p>
                  <p>
                    {guess.correct && allGuesses.length === 1
                      ? "🏆"
                      : guess.correct
                      ? "💵"
                      : guess.index === 5
                      ? "❌"
                      : guess.overUnder === "under"
                      ? "⬆️"
                      : "⬇️"}
                  </p>
                  <p>
                    <span
                      className={guess.overUnder === "under" ? "red" : "green"}
                    >
                      {guess.percentDiff === 0
                        ? ""
                        : guess.overUnder === "under"
                        ? "-"
                        : "+"}
                      {guess.percentDiff}%
                    </span>
                  </p>
                </div>
              );
            })}
        </div>
        <ShareButtons copiedShareStats={copiedShareStats} />
      </Modal>
    </RemoveScroll>
  );
};
