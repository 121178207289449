import { ProductLogo } from "./ProductLogo";

interface ProductPurchaseButtonProps {
  url: string;
  source: string;
  className?: string;
}

export const ProductPurchaseButton = ({
  url,
  source,
  className,
}: ProductPurchaseButtonProps) => {
  return (
    <button className={`product_purchase_button ${className || ""}`}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <span>FIND ON</span> <ProductLogo sourceName={source} />
      </a>
    </button>
  );
};
