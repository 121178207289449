import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import { ArrowIndicator } from "./ArrowIndicator";
import { GuessArea } from "../GuessArea/GuessArea";
import { Placeholder } from "./Placeholder";
import { Guesses } from "./Guesses";
import { Winner } from "../Winner/Winner";
import { Hints } from "./Hints";
import { AppContext } from "../../App";
import { waitImagesToLoad } from "../../waitImagesToLoad";
import ShockedClap from "../../assets/images/ShockedClap.gif";
import { ProductPurchaseButton } from "./ProductPurchaseButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ProductDetails.scss";

interface ProductDetailsProps {
  refreshingDataTime: boolean;
}

export const ProductDetails = ({ refreshingDataTime }: ProductDetailsProps) => {
  const {
    allGuesses,
    changeAllGuesses,
    darkMode,
    productDetails,
    changeProductDetails,
    currentlyPlayingDate,
    objectiveCurrentDate,
    currentFocusedImage,
    changeCurrentFocusedImage,
  } = useContext(AppContext);
  const [productLoading, changeProductLoading] = useState(false);
  const [productImagesDoneLoading, changeProductImagesDoneLoading] =
    useState(false);

  useEffect(() => {
    if (refreshingDataTime) {
      if (!productLoading) {
        changeProductLoading(true);
        if (currentFocusedImage !== 0) changeCurrentFocusedImage(0);
      }
    }
  }, [
    productLoading,
    refreshingDataTime,
    currentFocusedImage,
    changeCurrentFocusedImage,
  ]);

  useEffect(() => {
    const getProduct = async () => {
      changeProductLoading(true);
      await axios
        .get(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_PRODUCTION_SERVER || ""}/product`
            : "http://localhost:4000/product"
        )
        .then(async (res) => {
          changeProductLoading(false);
          changeProductDetails(res.data);
          await waitImagesToLoad(res.data.images).then(() =>
            changeProductImagesDoneLoading(true)
          );
        })
        .catch((e) => {
          console.error(e);
        });
    };
    getProduct();
  }, [changeProductDetails]);

  const handleChangeCarousel = (index: number) => {
    changeCurrentFocusedImage(index);
  };

  return (
    <div className={`product_details_container ${darkMode ? "dark" : ""}`}>
      {productLoading || !productImagesDoneLoading ? (
        <Placeholder />
      ) : productDetails ? (
        <>
          {/* Preload one-move win gif */}
          <div className="cached_gifs">
            <img src={ShockedClap} alt="Shocked Clap gif" />
          </div>
          <div className="carousel_wrapper">
            <Carousel
              axis="horizontal"
              emulateTouch={true}
              infiniteLoop={true}
              renderArrowPrev={(clickHandler: () => void) => (
                <ArrowIndicator clickHandler={clickHandler} />
              )}
              renderArrowNext={(clickHandler: () => void) => (
                <ArrowIndicator clickHandler={clickHandler} right />
              )}
              selectedItem={currentFocusedImage}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              verticalSwipe={undefined}
              onChange={handleChangeCarousel}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={40}
            >
              <div>
                <img
                  className="carousel_image"
                  src={productDetails.images[0]}
                  alt="Today's Apparle product"
                />
              </div>
              <div>
                <img
                  className="carousel_image"
                  src={productDetails.images[1]}
                  alt="Today's Apparle product on a model"
                />
              </div>
            </Carousel>
            {currentlyPlayingDate !== objectiveCurrentDate ? (
              <p
                className={`archived_game_disclaimer ${darkMode ? "dark" : ""}`}
              >
                Archived Game #{productDetails.gameNum}
                <br />
                {productDetails.date}
              </p>
            ) : productDetails?.weekStarting &&
              Number(productDetails?.index) >= 0 ? (
              <p
                className={`archived_game_disclaimer ${darkMode ? "dark" : ""}`}
              >
                Weekly 50 Game #{Number(productDetails.index) + 1}
                <br />
                Week of {productDetails.weekStarting}
              </p>
            ) : (
              <></>
            )}
            {allGuesses.length >= 6 ||
            allGuesses.some((guess) => guess.correct) ? (
              <ProductPurchaseButton
                url={productDetails.url}
                source={productDetails.source}
              />
            ) : (
              <></>
            )}
          </div>
          <ul className={`carousel_slider ${darkMode ? "dark" : ""}`}>
            <li>
              <button
                type="button"
                role="tab"
                aria-label="1 of 2"
                aria-selected={currentFocusedImage === 0 ? "true" : "false"}
                onClick={() => handleChangeCarousel(0)}
              >
                0
              </button>
            </li>
            <li>
              <button
                type="button"
                role="tab"
                aria-label="2 of 2"
                aria-selected={currentFocusedImage === 1 ? "true" : "false"}
                onClick={() => handleChangeCarousel(1)}
              >
                1
              </button>
            </li>
          </ul>
          <GuessArea
            allGuesses={allGuesses}
            changeAllGuesses={changeAllGuesses}
          />
          <Hints allGuesses={allGuesses} />
          {allGuesses.length >= 6 ||
          allGuesses.some((guess) => guess.correct) ? (
            <Winner allGuesses={allGuesses} />
          ) : (
            <Guesses allGuesses={allGuesses} />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
