import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { Header } from "../components/Header/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { format } from "date-fns-tz";
import { ProductDetails } from "../components/ProductDetails/ProductDetails";
import { PrivacyPolicy } from "../components/Policies/PrivacyPolicy";
import { TermsAndConditions } from "../components/Policies/Terms";

export const Main = () => {
  const { fullTimezoneDate } = useContext(AppContext);
  const [refreshingDataTime, changeRefreshingDataTime] = useState(false);

  // Server-side is refreshing actor data, show loading component
  useEffect(() => {
    const endDateArr = fullTimezoneDate.split(" ");
    const endTime = endDateArr[0];
    const endTimeArr = endTime.split(":");
    let hours: number | string = endTimeArr[0];
    const minutes = endTimeArr[1];
    const endTimeMorningNight = endDateArr[1];

    if (endTimeMorningNight === "PM") {
      if (hours !== "12") {
        hours = Number(hours) + 12;
      } else {
        if (Number(hours) <= 10) hours = `0${hours}`;
      }
    } else {
      if (hours === "12") {
        hours = "00";
      } else {
        if (Number(hours) <= 10) hours = `0${hours}`;
      }
    }

    const timesArr: string[] = [];
    for (let i = 0; i <= 10; i++) {
      timesArr.push(`${hours}${minutes}${i < 10 ? "0" + i : i}`);
    }
    const timeInterval = setInterval(() => {
      const currentHoursSeconds = format(new Date(), "HHmmss", {
        timeZone: "America/New_York",
      });
      if (timesArr.includes(currentHoursSeconds)) {
        changeRefreshingDataTime(true);

        // Reload page and fetch new data
        if (currentHoursSeconds === `${hours}${minutes}10`) {
          window.location.reload();
        }
      }
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [fullTimezoneDate]);

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route
            path="/"
            element={<ProductDetails refreshingDataTime={refreshingDataTime} />}
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};
