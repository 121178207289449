import React, { ChangeEvent, useContext, useState } from "react";
import { Guess } from "../../interfaces/Guess.interface";
import { AppContext } from "../../App";
import round from "lodash.round";
import "./GuessArea.scss";
import "../../index.scss";

interface GuessAreaProps {
  allGuesses: Guess[];
  changeAllGuesses: React.Dispatch<React.SetStateAction<Guess[]>>;
}

export const GuessArea = ({ allGuesses, changeAllGuesses }: GuessAreaProps) => {
  const {
    gameStatistics,
    changeGameStatistics,
    darkMode,
    productDetails,
    currentlyPlayingDate,
    objectiveCurrentDate,
  } = useContext(AppContext);
  const [guessValue, changeGuessValue] = useState("");

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    changeGuessValue(
      e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
    );
  };

  const handleNewGuess = (finalPrice: number | undefined) => {
    if (finalPrice && guessValue) {
      const guessedValue = Number(guessValue.replace(",", ""));
      const overOrUnder = finalPrice > guessedValue ? "under" : "over";
      const tenPercent = 0.1 * finalPrice;
      const tenPercentAbove = finalPrice + tenPercent;
      const tenPercentBelow = finalPrice - tenPercent;
      const correct =
        guessedValue >= tenPercentBelow && guessedValue <= tenPercentAbove;
      const percentDiff = Math.abs(
        ((finalPrice - guessedValue) / finalPrice) * 100
      );
      const cloneGuesses = [...allGuesses];
      changeGuessValue("");
      const currentGuessObj = {
        index: cloneGuesses.length,
        value: guessedValue,
        overUnder: overOrUnder,
        correct,
        percentDiff: round(percentDiff, 1),
      } as Guess;
      if (
        currentlyPlayingDate === objectiveCurrentDate &&
        // Not Weekly 50 game
        !productDetails?.weekStarting &&
        !productDetails?.index
      ) {
        changeGameStatistics({
          ...gameStatistics,
          todayGuesses: [...cloneGuesses, currentGuessObj],
        });
      }
      changeAllGuesses([...cloneGuesses, currentGuessObj]);
    }
  };

  const checkInputKey = (
    e: KeyboardEvent | any,
    finalPrice: number | undefined
  ) => {
    if (e.code === "Enter") handleNewGuess(finalPrice);
  };

  const formattedGuessValue = Number(guessValue).toLocaleString();

  return (
    <div
      className={`guess_area ${
        allGuesses.length >= 6 || allGuesses.some((guess) => guess.correct)
          ? "hidden"
          : ""
      }`}
    >
      <div className="guess_input_group">
        <span
          className={`input_dollar_sign ${
            guessValue && formattedGuessValue !== "0" ? "visible" : ""
          } ${darkMode ? "dark" : ""}`}
        >
          $
        </span>
        <input
          className={`guess_input ${
            guessValue && formattedGuessValue !== "0" ? "withValue" : ""
          } ${darkMode ? "dark" : ""}`}
          placeholder="Enter Price Guess (USD)"
          value={formattedGuessValue === "0" ? "" : formattedGuessValue}
          onChange={onChangeInput}
          onKeyDown={(e) => checkInputKey(e, productDetails?.price)}
          type="tel"
          autoFocus={true}
        />
        <div
          className={`enter_container ${darkMode ? "dark" : ""}`}
          onClick={() => handleNewGuess(productDetails?.price)}
        >
          →
        </div>
      </div>
    </div>
  );
};
