import React, { useContext } from "react";
import { AppContext } from "../../App";
import { Guess } from "../../interfaces/Guess.interface";
import Dancing from "../../assets/images/Dancing.gif";
import Disappointed from "../../assets/images/Disappointed.gif";

interface HintsProps {
  allGuesses: Guess[];
}

export const convertStringHtmlToSpan = (htmlString: string | undefined) => {
  if (htmlString && typeof htmlString === "string") {
    const splitOnWhiteSpaceArr = htmlString.split(/\b\s+/gm).join(" ");
    return (
      <span dangerouslySetInnerHTML={{ __html: `${splitOnWhiteSpaceArr}` }} />
    );
  } else {
    return htmlString;
  }
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const Hints = ({ allGuesses }: HintsProps) => {
  const { darkMode, productDetails } = useContext(AppContext);

  return (
    <>
      {/* Preload rest of gifs as guesses come in */}
      <div className="cached_gifs">
        {allGuesses.length > 0 && <img src={Dancing} alt="Dancing gif" />}
        {allGuesses.length > 3 && (
          <img src={Disappointed} alt="Disappointed gif" />
        )}
      </div>
      <div
        className={`all_hints_wrapper ${
          allGuesses.length >= 6 || allGuesses.some((guess) => guess.correct)
            ? "slide"
            : ""
        }`}
      >
        <div className={`hint_container ${darkMode ? "dark" : ""}`}>
          <h2
            className={`product_name ${
              !allGuesses.some((guess) => guess.correct)
                ? allGuesses.length === 0
                  ? "hidden"
                  : ""
                : ""
            }`}
          >
            {!allGuesses.some((guess) => guess.correct)
              ? allGuesses.length === 0
                ? "xxxxxx"
                : convertStringHtmlToSpan(productDetails?.brand)
              : convertStringHtmlToSpan(productDetails?.brand)}
          </h2>
        </div>
        <div className="secondThirdHintContainer">
          <div className={`hint_container ${darkMode ? "dark" : ""}`}>
            <p
              className={`hint_value ${
                !allGuesses.some((guess) => guess.correct)
                  ? allGuesses.length < 2
                    ? "hidden"
                    : ""
                  : ""
              }`}
            >
              {!allGuesses.some((guess) => guess.correct)
                ? allGuesses.length < 2
                  ? "xxxxxx"
                  : convertStringHtmlToSpan(
                      capitalizeFirstLetter(productDetails?.description || "")
                    )
                : convertStringHtmlToSpan(
                    capitalizeFirstLetter(productDetails?.description || "")
                  )}
            </p>
          </div>
          <div className={`hint_container gender ${darkMode ? "dark" : ""}`}>
            <p
              className={`hint_value ${
                !allGuesses.some((guess) => guess.correct)
                  ? allGuesses.length < 3
                    ? "hidden"
                    : ""
                  : ""
              }`}
            >
              {!allGuesses.some((guess) => guess.correct)
                ? allGuesses.length < 3
                  ? "xxxxxx"
                  : productDetails?.gender
                : productDetails?.gender}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
