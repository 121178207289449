import Caret from "../../assets/images/Caret.svg";
import "./ProductDetails.scss";

interface ArrowProps {
  clickHandler: () => void;
  right?: boolean;
}

export const ArrowIndicator = ({ clickHandler, right }: ArrowProps) => {
  return (
    <div
      className={`arrow_container ${right ? "right" : ""}`}
      onClick={clickHandler}
    >
      <img src={Caret.toString()} alt="Caret left" />
    </div>
  );
};
