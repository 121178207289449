import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import * as Ladda from "ladda";
import axios from "axios";
import { RemoveScroll } from "react-remove-scroll";
import ReactModal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AppContext } from "../../../App";
import "../Header.scss";
import "./Contact.scss";
import "ladda/dist/ladda.min.css";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transitionDelay: "0.15s",
    transition: "transform 0.5s ease",
  },
};

interface ContactProps {
  showContactModal: boolean;
  changeShowContactModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Contact = ({
  showContactModal,
  changeShowContactModal,
}: ContactProps) => {
  const { darkMode } = useContext(AppContext);
  const [serverState, setServerState] = useState({
    submitting: false,
    status: {
      ok: false,
      msg: "",
    },
  });
  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const [message, changeMessage] = useState("");

  const laddaRef = useRef<HTMLButtonElement | null>(null);

  const handleServerResponse = (
    ok: boolean,
    msg: string,
    form: HTMLFormElement
  ) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });

    if (ok) {
      form.reset();
      changeName("");
      changeEmail("");
      changeMessage("");
    }
  };

  useEffect(() => {
    if (!showContactModal) {
      if (
        serverState.submitting ||
        serverState.status.ok ||
        serverState.status.msg
      ) {
        setServerState({
          submitting: false,
          status: {
            ok: false,
            msg: "",
          },
        });
      }
    }
  }, [
    serverState.status.msg,
    serverState.status.ok,
    serverState.submitting,
    showContactModal,
  ]);

  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    setServerState({ submitting: true, status: { ok: false, msg: "" } });

    axios({
      method: "post",
      url: `${process.env.REACT_APP_GETFORM_CONTACT_ENDPOINT}`,
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(
          true,
          "Your Apparle contact form submission has been sent successfully! We will get back to you shortly.",
          form
        );
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  useEffect(() => {
    if (laddaRef && laddaRef.current) {
      let l = Ladda.create(laddaRef.current);
      if (serverState.submitting) {
        l.start();
      } else {
        if (l.isLoading()) {
          l.stop();
        }
      }
    }
  }, [serverState.submitting]);

  const handleCloseModal = () => {
    changeShowContactModal(false);
  };

  return (
    <RemoveScroll enabled={showContactModal}>
      <ReactModal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="Support Modal"
        className={`modal_contents contact_container ${
          showContactModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showContactModal && {
            overlay: {
              transform: "translateY(150%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>CONTACT APPARLE</h2>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <div className="contact_prompt">
          <p>
            If you have any questions, comments, or requests - or if you think
            you've found an Apparle bug - feel free to reach out to us!
          </p>
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className={`contact_input_container ${darkMode ? "dark" : ""}`}>
            <label htmlFor="name_field">Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              id="name_field"
              maxLength={150}
              onChange={(e) => changeName(e.target.value)}
            />
          </div>
          <div className={`contact_input_container ${darkMode ? "dark" : ""}`}>
            <label htmlFor="email_field">Email</label>
            <input
              className="form-control"
              type="email"
              name="email"
              id="email_field"
              aria-describedby="email"
              maxLength={150}
              onChange={(e) => changeEmail(e.target.value)}
            />
          </div>
          <div className={`contact_input_container ${darkMode ? "dark" : ""}`}>
            <label htmlFor="textarea_field">Message</label>
            <textarea
              className="form-control"
              name="message"
              id="textarea_field"
              maxLength={1000}
              rows={7}
              onChange={(e) => changeMessage(e.target.value)}
            />
          </div>
          <div className="submit_button_container">
            <button
              type="submit"
              className={`modal_play_button ladda-button dark ${
                serverState.submitting ? "loading" : ""
              } ${name && email && message ? "" : "disabled"}`}
              data-style="zoom-out"
              ref={laddaRef}
            >
              <span className="ladda-label">SUBMIT</span>
            </button>
          </div>
          {serverState.status.msg && (
            <div
              className={`contact_success_message_container ${
                darkMode ? "dark" : ""
              }`}
            >
              <p>
                <AiOutlineCheckCircle
                  className="contact_success_checkmark"
                  color={darkMode ? "rgb(107, 168, 100)" : "rgb(84, 155, 2)"}
                  size={20}
                />{" "}
                {serverState.status.msg}
              </p>
            </div>
          )}
        </form>
      </ReactModal>
    </RemoveScroll>
  );
};
