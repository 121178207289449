export const waitImagesToLoad = async (srcArray: string[]) => {
  const promises = srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = src;
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => reject();
    });
  });

  return await Promise.allSettled(promises);
};
