import { Breathing } from "react-shimmer";

export const Placeholder = () => {
  return (
    <div className="loading_shimmers_container">
      <Breathing className="loading_shimmer" width={200} height={200} />
      <Breathing className="loading_shimmer_input" width={200} height={200} />
      <Breathing
        className="loading_shimmer_hint_title"
        width={200}
        height={200}
      />
      <div className="secondThirdShimmerHintsContainer">
        <Breathing
          className="loading_shimmer_hint_description"
          width={200}
          height={200}
        />
        <Breathing
          className="loading_shimmer_hint_third"
          width={200}
          height={200}
        />
      </div>
    </div>
  );
};
