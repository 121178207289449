import { MouseEventHandler } from "react";
import ShockedClap from "../../assets/images/ShockedClap.gif";
import ShockedClapStill from "../../assets/images/ShockedClapStill.jpg";
import Dancing from "../../assets/images/Dancing.gif";
import DancingStill from "../../assets/images/DancingStill.jpg";
import Disappointed from "../../assets/images/Disappointed.gif";
import DisappointedStill from "../../assets/images/DisappointedStill.jpg";
import { BsPlayCircle } from "react-icons/bs";

interface GifImageProps {
  win: boolean;
  oneMove: boolean;
  imageClicked: boolean;
  handleGifClick: MouseEventHandler<HTMLImageElement>;
}

export const GifImage = ({
  win,
  oneMove,
  imageClicked,
  handleGifClick,
}: GifImageProps) => {
  if (win) {
    if (oneMove) {
      return (
        <div className="winner_gif_container" onClick={handleGifClick}>
          <img
            className="winner_gif"
            src={imageClicked ? ShockedClap : ShockedClapStill}
            alt="Winner gif"
          />
          {!imageClicked && <BsPlayCircle color={"#fff"} size={50} />}
        </div>
      );
    } else {
      return (
        <div className="winner_gif_container" onClick={handleGifClick}>
          <img
            className="winner_gif"
            src={imageClicked ? Dancing : DancingStill}
            alt="Winner gif"
          />
          {!imageClicked && <BsPlayCircle color={"#fff"} size={50} />}
        </div>
      );
    }
  } else {
    return (
      <div className="winner_gif_container" onClick={handleGifClick}>
        <img
          className="loss_gif"
          src={imageClicked ? Disappointed : DisappointedStill}
          alt="Disappointed gif"
        />
        {!imageClicked && <BsPlayCircle color={"#fff"} size={50} />}
      </div>
    );
  }
};
