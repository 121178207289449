import { useReward } from "react-rewards";
import { useCallback, useContext, useEffect, useState } from "react";
import { Guess } from "../../interfaces/Guess.interface";
import { CountdownTimer } from "../Countdown/Countdown";
import { GifImage } from "./GifImage";
import { AppContext } from "../../App";
import { AllGuessesModal } from "./AllGuessesModal";
import { ShareButtons } from "./ShareButtons";
import axios from "axios";
import round from "lodash.round";
import { GlobalStatsModal } from "./GlobalStatsModal";
import { AiOutlineTwitter } from "react-icons/ai";
import { ShareButtonTooltip } from "./ShareButtonTooltip";
import "./Winner.scss";

interface WinnerProps {
  allGuesses: Guess[];
}

export const Winner = ({ allGuesses }: WinnerProps) => {
  const [gameLoss, changeGameLoss] = useState(false);
  const [confettiFired, changeConfettiFired] = useState(false);
  const [imageClicked, changeImageClicked] = useState(false);
  const [showAllGuessesModal, changeShowAllGuessesModal] = useState(false);
  const [showGlobalStatsModal, changeShowGlobalStatsModal] = useState(false);
  const [copiedShareStats, changeCopiedShareStats] = useState("");

  // Global stats
  const [globalStats, changeGlobalStats] = useState<
    { [key: string]: number } | undefined
  >();
  const [betterPercentage, changeBetterPercentage] = useState(0);
  const [totalGlobalPlays, changeTotalGlobalPlays] = useState(0);

  const {
    fullTimezoneDate,
    objectiveCurrentDate,
    currentlyPlayingDate,
    gameStatistics,
    changeGameStatistics,
    darkMode,
    productDetails,
  } = useContext(AppContext);

  const { reward: confettiReward, isAnimating } = useReward(
    "confettiReward",
    "emoji",
    {
      emoji: allGuesses.length === 1 ? ["🏆"] : ["💵"],
      lifetime: 400,
      zIndex: 9999,
      elementSize: 50,
      elementCount: 50,
      spread: 1000,
    }
  );

  const modifyLocalGlobalStatsState = (
    stats: { [key: string]: number },
    score: string
  ) => {
    const totalPlays = Object.values(stats).reduce((a, b) => a + b, 0);
    changeGlobalStats(stats);
    changeTotalGlobalPlays(totalPlays);
    let totalWorse = 0;
    const allPossibleOutcomes = ["1", "2", "3", "4", "5", "6", "loss"];
    const foundIndex = allPossibleOutcomes.indexOf(score);
    if (foundIndex >= 0 && foundIndex < 6) {
      const allWorse = allPossibleOutcomes.slice(foundIndex + 1);
      for (const outcome of allWorse) {
        totalWorse += stats[outcome];
      }
    }
    if (totalWorse > 0) {
      changeBetterPercentage(round((totalWorse / totalPlays) * 100, 1));
    }
  };

  useEffect(() => {
    if (
      productDetails?.weekStarting &&
      gameStatistics?.weekly50?.scores?.length > 0 &&
      !globalStats
    ) {
      const foundAlreadyPlayedGame = gameStatistics.weekly50.scores.find(
        (score) => score.index === productDetails?.index
      );
      if (foundAlreadyPlayedGame) {
        modifyLocalGlobalStatsState(
          productDetails?.scoreChart as { [key: string]: number },
          foundAlreadyPlayedGame.result || ""
        );
      }
    }
  }, [
    globalStats,
    gameStatistics.weekly50?.scores,
    productDetails?.index,
    productDetails?.scoreChart,
    productDetails?.weekStarting,
  ]);

  const updateGlobalStats = useCallback(
    async (score: string, isWeekly50Game?: boolean) => {
      const requestObj = isWeekly50Game
        ? {
            weekStarting: productDetails?.weekStarting,
            index: productDetails?.index,
            score,
          }
        : {
            date: productDetails?.date,
            gameNum: productDetails?.gameNum,
            score,
          };
      await axios
        .put(
          process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_PRODUCTION_SERVER || ""}/${
                isWeekly50Game ? "weekly50stats" : "stats"
              }`
            : `http://localhost:4000/${
                isWeekly50Game ? "weekly50stats" : "stats"
              }`,
          requestObj,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          const resScoreChart = res.data.scoreChart as {
            [key: string]: number;
          };
          if (resScoreChart) modifyLocalGlobalStatsState(resScoreChart, score);
        })
        .catch((e) => console.error(e));
    },
    [
      productDetails?.date,
      productDetails?.gameNum,
      productDetails?.index,
      productDetails?.weekStarting,
    ]
  );

  const getTodayGlobalStats = useCallback(async (score: string) => {
    await axios
      .get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? `${process.env.REACT_APP_PRODUCTION_SERVER || ""}/stats`
          : "http://localhost:4000/stats"
      )
      .then((res) => {
        const resScoreChart = res.data.scoreChart as {
          [key: string]: number;
        };
        if (resScoreChart) modifyLocalGlobalStatsState(resScoreChart, score);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (allGuesses.length >= 6) {
      if (!allGuesses.some((guess) => guess.correct)) {
        changeGameLoss(true);
        changeImageClicked(true);

        // For weekly 50 only
        if (
          productDetails?.weekStarting &&
          Number(productDetails?.index) >= 0 &&
          productDetails.weekStarting === gameStatistics.weekly50.weekStarting
        ) {
          if (
            !gameStatistics.weekly50.scores.find(
              (el) => el.index === productDetails.index
            )
          ) {
            changeGameStatistics({
              ...gameStatistics,
              weekly50: {
                ...gameStatistics.weekly50,
                scores: [
                  ...gameStatistics.weekly50.scores,
                  {
                    index: Number(productDetails?.index),
                    result: "loss",
                  },
                ],
              },
            });
            // Update loss score for Weekly 50 game
            updateGlobalStats("loss", true);
          }
        } else {
          if (currentlyPlayingDate === objectiveCurrentDate) {
            if (!gameStatistics.playedToday) {
              const newScoreChart = { ...gameStatistics.scoreChart };
              newScoreChart.loss += 1;

              changeGameStatistics({
                ...gameStatistics,
                lastPlayed: objectiveCurrentDate,
                playedToday: true,
                currentStreak: 0,
                scoreChart: newScoreChart,
                todayGuesses: allGuesses,
              });

              // If not weekly 50 game
              if (!productDetails?.weekStarting) {
                updateGlobalStats("loss");
              }
            } else {
              getTodayGlobalStats("loss");
            }
          }
        }

        setTimeout(() => changeImageClicked(false), 5000);
      }
    }
  }, [
    allGuesses,
    changeGameStatistics,
    gameStatistics,
    objectiveCurrentDate,
    updateGlobalStats,
    getTodayGlobalStats,
    currentlyPlayingDate,
    productDetails?.index,
    productDetails?.weekStarting,
  ]);

  useEffect(() => {
    if (
      !isAnimating &&
      !confettiFired &&
      allGuesses.some((guess) => guess.correct)
    ) {
      changeConfettiFired(true);
      changeImageClicked(true);

      // For weekly 50 only
      if (
        productDetails?.weekStarting &&
        Number(productDetails?.index) >= 0 &&
        productDetails.weekStarting === gameStatistics.weekly50.weekStarting
      ) {
        if (
          !gameStatistics.weekly50.scores.find(
            (el) => el.index === productDetails.index
          )
        ) {
          changeGameStatistics({
            ...gameStatistics,
            weekly50: {
              ...gameStatistics.weekly50,
              scores: [
                ...gameStatistics.weekly50.scores,
                {
                  index: Number(productDetails?.index),
                  result: allGuesses.length.toString(),
                },
              ],
            },
          });
          // Update score for Weekly 50 game
          updateGlobalStats(allGuesses.length.toString(), true);
        }
      } else {
        if (currentlyPlayingDate === objectiveCurrentDate) {
          if (!gameStatistics.playedToday) {
            const newScoreChart = { ...gameStatistics.scoreChart };
            const numGuesses = JSON.stringify(allGuesses.length) as
              | "1"
              | "2"
              | "3"
              | "4"
              | "5"
              | "6";
            if (gameStatistics.scoreChart[numGuesses] >= 0) {
              newScoreChart[numGuesses] =
                gameStatistics.scoreChart[numGuesses] + 1;
              const currentStreak = gameStatistics.currentStreak + 1;

              changeGameStatistics({
                ...gameStatistics,
                lastPlayed: objectiveCurrentDate,
                playedToday: true,
                currentStreak,
                maxStreak: Math.max(currentStreak, gameStatistics.maxStreak),
                scoreChart: newScoreChart,
                todayGuesses: allGuesses,
              });

              // If not weekly 50 game
              if (!productDetails?.weekStarting) {
                updateGlobalStats(allGuesses.length.toString());
              }
            }
          } else {
            getTodayGlobalStats(allGuesses.length.toString());
          }
        }
      }

      setTimeout(() => {
        confettiReward();
      }, 500);
      setTimeout(() => changeImageClicked(false), 5000);
    }
  }, [
    productDetails?.index,
    productDetails?.weekStarting,
    isAnimating,
    confettiReward,
    confettiFired,
    allGuesses,
    changeGameStatistics,
    gameStatistics,
    objectiveCurrentDate,
    currentlyPlayingDate,
    updateGlobalStats,
    getTodayGlobalStats,
  ]);

  useEffect(() => {
    const clonedGuesses = [...allGuesses];
    const sortedGuesses = clonedGuesses.sort((a, b) => a.index - b.index);
    const shareStatement = [
      `Apparle ${
        productDetails?.weekStarting
          ? `Weekly 50 #${Number(productDetails?.index) + 1}\n${
              productDetails.weekStarting
            } ${sortedGuesses.length}/6`
          : ""
      }${
        productDetails?.weekStarting
          ? ""
          : `#${productDetails?.gameNum || 1} ${sortedGuesses.length}/6`
      }`,
    ];
    sortedGuesses.forEach((guess, i) => {
      shareStatement.push(
        `${
          guess.correct && allGuesses.length === 1
            ? "🏆"
            : guess.correct
            ? "💵"
            : guess.index === 5
            ? "❌"
            : guess.overUnder === "under"
            ? "⬆️"
            : "⬇️"
        } ${
          guess.percentDiff === 0 ? "" : guess.overUnder === "under" ? "-" : "+"
        }${guess.percentDiff}%`
      );
    });
    let finalStatement = shareStatement.join("\n");
    finalStatement += "\n\nhttps://apparle.com";
    changeCopiedShareStats(finalStatement);
  }, [
    allGuesses,
    productDetails?.gameNum,
    productDetails?.index,
    productDetails?.weekStarting,
  ]);

  const handleGifClick = () => {
    if (!imageClicked) {
      changeImageClicked(true);
    } else {
      changeImageClicked(false);
    }
  };

  const formatPriceNum = (price: number) => {
    return price?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
  };

  return (
    <div
      className={`winner_container ${gameLoss ? "loss" : ""} ${
        darkMode ? "dark" : ""
      }`}
    >
      <div className="actual_price_container" id="confettiReward">
        <p>Actual price:</p>
        <p className="winner_final_price">
          ${formatPriceNum(Number(productDetails?.price))}
        </p>
        <div className="acceptable_range_container">
          <p>Acceptable guess range:</p>
          <p>
            $
            {formatPriceNum(
              Number(productDetails?.price) -
                Number(productDetails?.price) * 0.1
            )}{" "}
            - $
            {formatPriceNum(
              Number(productDetails?.price) +
                Number(productDetails?.price) * 0.1
            )}
          </p>
        </div>
      </div>
      <AllGuessesModal
        copiedShareStats={copiedShareStats}
        showAllGuessesModal={showAllGuessesModal}
        changeShowAllGuessesModal={changeShowAllGuessesModal}
      />
      <GlobalStatsModal
        copiedShareStats={copiedShareStats}
        globalStats={globalStats}
        totalGlobalPlays={totalGlobalPlays}
        showGlobalStatsModal={showGlobalStatsModal}
        changeShowGlobalStatsModal={changeShowGlobalStatsModal}
      />
      {allGuesses.some((guess) => guess.correct) ? (
        <div className="winner_statement_container">
          <p>
            <span className="emoji left">
              {allGuesses.length === 1 ? "🏆" : "💵"}
            </span>
            <span className="winner_statement_inner_container">
              You got it in {allGuesses.length} guess
              {allGuesses.length === 1 ? "" : "es"}!
              <b
                className="guesses_link"
                onClick={() => changeShowAllGuessesModal(true)}
              >
                View{" "}
                {productDetails?.weekStarting
                  ? "all"
                  : currentlyPlayingDate === objectiveCurrentDate
                  ? "today's"
                  : "all"}{" "}
                guesses
              </b>
              <span className="emoji right">
                {allGuesses.length === 1 ? "🏆" : "💵"}
              </span>
            </span>
          </p>
          <GifImage
            win={true}
            oneMove={allGuesses.length === 1}
            imageClicked={imageClicked}
            handleGifClick={handleGifClick}
          />
          {globalStats && betterPercentage && betterPercentage > 0 ? (
            <div className="global_stats_statement">
              <p>
                You scored better than <br /> <b>{betterPercentage}%</b> of
                other players.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="loss_statement_container">
          <p className="loss">
            <span className="emoji left loss">❌</span>
            <span className="loss_statement_inner_container">
              <span>Better luck next time!</span>{" "}
              <b
                className="guesses_link"
                onClick={() => changeShowAllGuessesModal(true)}
              >
                View{" "}
                {currentlyPlayingDate === objectiveCurrentDate
                  ? "today's"
                  : "all"}{" "}
                guesses
              </b>
            </span>
            <span className="emoji right loss">❌</span>
          </p>
          <GifImage
            win={false}
            oneMove={false}
            imageClicked={imageClicked}
            handleGifClick={handleGifClick}
          />
        </div>
      )}
      <ShareButtons
        copiedShareStats={copiedShareStats}
        landingWinner={true}
        changeShowGlobalStatsModal={changeShowGlobalStatsModal}
      />
      <div className="winner_countdown_timer">
        Next Apparle in <CountdownTimer />
        <div className="statistics_time_disclaimer">
          <p>
            at 12:00 AM Eastern Time (GMT-4)
            {!fullTimezoneDate.includes("12:00 AM") && " or"}
          </p>
          {!fullTimezoneDate.includes("12:00 AM") && (
            <>
              <p>{fullTimezoneDate}</p>
            </>
          )}
        </div>
      </div>
      <div
        className={`bottom_buttons_container grid ${darkMode ? "dark" : ""}`}
      >
        <ShareButtonTooltip copiedShareStats={copiedShareStats} />
        <a
          className="twitter_share_link"
          href={`https://twitter.com/intent/tweet?${
            copiedShareStats
              ? `&text=${encodeURIComponent(copiedShareStats.trim())}`
              : ""
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="twitter">
            <AiOutlineTwitter size={25} />
            TWEET
          </button>
        </a>
      </div>
    </div>
  );
};
