import { useContext, useState } from "react";
import { FaShareAlt } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { AppContext } from "../../App";
import { copyToClipboard } from "./copyToClipboard";

interface ShareButtonTooltipProps {
  copiedShareStats: string;
}

export const ShareButtonTooltip = ({
  copiedShareStats,
}: ShareButtonTooltipProps) => {
  const { darkMode } = useContext(AppContext);
  const [copiedActive, changeCopiedActive] = useState(false);
  const [delayedTooltip, changeDelayedTooltip] = useState(false);

  const handleShareButtonClick = () => {
    copyToClipboard(copiedShareStats);
    changeCopiedActive(true);
    setTimeout(() => changeDelayedTooltip(true), 300);
    setTimeout(() => changeDelayedTooltip(false), 2000);
    setTimeout(() => changeCopiedActive(false), 2300);
  };
  return (
    <>
      <Tooltip
        id="share-button-tooltip"
        isOpen={copiedActive}
        noArrow={true}
        className={`${delayedTooltip ? "show" : ""} ${darkMode ? "dark" : ""}`}
        offset={2}
      />
      <button
        className="share_button"
        data-tooltip-id="share-button-tooltip"
        data-tooltip-content="Copied!"
        onClick={handleShareButtonClick}
      >
        <FaShareAlt size={20} />
        SHARE
      </button>
    </>
  );
};
