import { useContext } from "react";
import { AppContext } from "../../App";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Guess } from "../../interfaces/Guess.interface";

Chart.register(CategoryScale);

interface GraphDistributionProps {
  globalStats?:
    | {
        [key: string]: number;
      }
    | undefined;
}

export const GraphDistribution = ({ globalStats }: GraphDistributionProps) => {
  const { gameStatistics, darkMode, productDetails } = useContext(AppContext);

  const labels = Object.keys(gameStatistics?.scoreChart || {});

  const barOptions = {
    animation: {
      duration: 2000,
      tension: {
        easing: "linear",
      },
    },
    indexAxis: "y" as const,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
          beginAtZero: true,
          major: {
            enabled: false,
          },
          minor: {
            enabled: false,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (index: number) => {
            const allKeys = Object.keys(gameStatistics?.scoreChart || {});
            const currentKey = allKeys[index];
            if (currentKey === "loss") return "❌";
            return currentKey;
          },
          padding: -0.5,
          color: darkMode ? "#fff" : "#000",
          font: {
            family: "Helvetica Neue",
            size: 12,
            weight: 700,
          },
          major: {
            enabled: false,
          },
          minor: {
            enabled: false,
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "start",
        font: {
          weight: "bold",
        },
        padding: {
          right: 7,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: globalStats
          ? Object.values(globalStats)
          : Object.values(gameStatistics?.scoreChart || {}),
        backgroundColor: labels.map((label, i) => {
          if (
            globalStats &&
            productDetails?.weekStarting &&
            Number(productDetails?.index) >= 0
          ) {
            const allWeekly50Scores = gameStatistics?.weekly50?.scores;
            const foundWeekly50Score = allWeekly50Scores.find(
              (game) => game.index === productDetails.index
            );
            if (foundWeekly50Score) {
              if (label === "loss" && foundWeekly50Score.result === "loss") {
                return "#CC2936";
              } else if (label === foundWeekly50Score.result) {
                return "rgb(107,168,100)";
              } else {
                return "rgb(120,124,127)";
              }
            } else {
              return "rgb(120,124,127)";
            }
          } else {
            if (gameStatistics.playedToday) {
              const todayCorrectIndex = gameStatistics.todayGuesses.findIndex(
                (guess: Guess) => guess.correct
              );
              const todayGuesses = todayCorrectIndex + 1;
              if (!todayGuesses) {
                if (label === "loss") return "#CC2936";
                return "rgb(120,124,127)";
              }
              if (label === todayGuesses.toString()) {
                return "rgb(107,168,100)";
              } else {
                return "rgb(120,124,127)";
              }
            } else {
              return "rgb(120,124,127)";
            }
          }
        }),
        borderColor: "transparent",
        barThickness: 20,
        minBarLength: 25,
      },
    ],
  };

  return (
    <Bar
      options={barOptions as any}
      plugins={[ChartDataLabels]}
      data={data}
      redraw={globalStats ? false : true}
    />
  );
};
