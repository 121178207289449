import OldNavyLogo from "../../assets/images/OldNavyLogo.svg";
import HMLogo from "../../assets/images/H&MLogo.svg";
import GAPLogo from "../../assets/images/GAPLogo.svg";
import MacysLogo from "../../assets/images/MacysLogo.svg";
import FarfetchLogo from "../../assets/images/FarfetchLogo.svg";
import RevolveLogo from "../../assets/images/RevolveLogo.svg";
import Forever21Logo from "../../assets/images/Forever21Logo.svg";
import JCrewLogo from "../../assets/images/JCrewLogo.svg";
import KohlsLogo from "../../assets/images/KohlsLogo.svg";
import JCPenneyLogo from "../../assets/images/JCPenneyLogo.svg";
import MovadoLogo from "../../assets/images/MovadoLogo.svg";
import DvfLogo from "../../assets/images/DvfLogo.svg";
import LauraLogo from "../../assets/images/LauraLogo.svg";
import DailyHauteLogo from "../../assets/images/DailyHauteLogo.svg";
import MaisonDeFashionLogo from "../../assets/images/MaisonDeFashionLogo.svg";
import DerekLamLogo from "../../assets/images/DerekLamLogo.svg";
import RalphLaurenLogo from "../../assets/images/RalphLaurenLogo.svg";
import DiorLogo from "../../assets/images/DiorLogo.svg";
import AmazonLogo from "../../assets/images/AmazonLogo.svg";
import TJMaxxLogo from "../../assets/images/TJMaxxLogo.svg";
import GarageLogo from "../../assets/images/GarageLogo.svg";
import MarshallsLogo from "../../assets/images/MarshallsLogo.svg";
import GucciLogo from "../../assets/images/GucciLogo.svg";
import TargetLogo from "../../assets/images/TargetLogo.svg";
import MatchesFashionLogo from "../../assets/images/MatchesFashionLogo.svg";
import SarahFlintLogo from "../../assets/images/SarahFlintLogo.svg";
import BurberryLogo from "../../assets/images/BurberryLogo.svg";
import KSwissLogo from "../../assets/images/KSwissLogo.svg";
import BloomingdalesLogo from "../../assets/images/BloomingdalesLogo.svg";
import KiplingLogo from "../../assets/images/KiplingLogo.svg";
import PsychoBunnyLogo from "../../assets/images/PsychoBunnyLogo.svg";
import WalmartLogo from "../../assets/images/WalmartLogo.svg";

export const ProductLogo = ({ sourceName }: { sourceName: string }) => {
  if (sourceName === "Farfetch") {
    return (
      <img
        className="farfetch_logo"
        src={FarfetchLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Diane von Furstenberg") {
    return (
      <img
        className="dvf_logo"
        src={DvfLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Maison De Fashion") {
    return (
      <img
        className="maison_de_fashion_logo"
        src={MaisonDeFashionLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Matches Fashion") {
    return (
      <img
        className="matches_fashion_logo"
        src={MatchesFashionLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Sarah Flint") {
    return (
      <img
        className="sarah_flint_logo"
        src={SarahFlintLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Burberry") {
    return (
      <img
        className="burberry_logo"
        src={BurberryLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Ralph Lauren") {
    return (
      <img
        className="ralph_lauren_logo"
        src={RalphLaurenLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Dior") {
    return (
      <img
        className="dior_logo"
        src={DiorLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Gucci") {
    return (
      <img
        className="gucci_logo"
        src={GucciLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Derek Lam") {
    return (
      <img
        className="derek_lam_logo"
        src={DerekLamLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "LAURA") {
    return (
      <img
        className="laura_logo"
        src={LauraLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Forever 21") {
    return (
      <img
        className="forever21_logo"
        src={Forever21Logo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "H&M") {
    return (
      <img
        className="hm_logo"
        src={HMLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "JCPenney") {
    return (
      <img
        className="jcpenney_logo"
        src={JCPenneyLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Daily Haute") {
    return (
      <img
        className="daily_haute_logo"
        src={DailyHauteLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "J.Crew") {
    return (
      <img
        className="jcrew_logo"
        src={JCrewLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "K-Swiss") {
    return (
      <img
        className="kswiss_logo"
        src={KSwissLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Bloomingdales") {
    return (
      <img
        className="bloomingdales_logo"
        src={BloomingdalesLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Kipling") {
    return (
      <img
        className="kipling_logo"
        src={KiplingLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Psycho Bunny") {
    return (
      <img
        className="psycho_bunny_logo"
        src={PsychoBunnyLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Kohl's") {
    return (
      <img
        className="kohls_logo"
        src={KohlsLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Macy's") {
    return (
      <img
        className="macys_logo"
        src={MacysLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Revolve") {
    return (
      <img
        className="revolve_logo"
        src={RevolveLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Old Navy") {
    return (
      <img
        src={OldNavyLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "GAP") {
    return (
      <img
        className="gap_logo"
        src={GAPLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "TJ Maxx") {
    return (
      <img
        className="tj_maxx_logo"
        src={TJMaxxLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Marshalls") {
    return (
      <img
        className="marshalls_logo"
        src={MarshallsLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Target") {
    return (
      <img
        className="target_logo"
        src={TargetLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Garage") {
    return (
      <img
        className="garage_logo"
        src={GarageLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Movado") {
    return (
      <img
        className="movado_logo"
        src={MovadoLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Amazon") {
    return (
      <img
        className="amazon_logo"
        src={AmazonLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else if (sourceName === "Walmart") {
    return (
      <img
        className="walmart_logo"
        src={WalmartLogo.toString()}
        alt={`${sourceName} brand source logo`}
      />
    );
  } else {
    return <></>;
  }
};
