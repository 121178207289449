import { useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { AppContext } from "../../App";
import { CountdownTimer } from "../Countdown/Countdown";
import "./HowToPlay.scss";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transition: "transform 0.5s ease",
  },
};

export const HowToPlay = () => {
  const {
    fullTimezoneDate,
    showHowToPlayModal,
    changeShowHowToPlayModal,
    darkMode,
  } = useContext(AppContext);
  const handleCloseModal = () => {
    changeShowHowToPlayModal(false);
  };

  Modal.setAppElement("#root");

  return (
    <RemoveScroll enabled={showHowToPlayModal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="How To Play Modal"
        className={`modal_contents how_to_play_modal ${
          showHowToPlayModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showHowToPlayModal && {
            overlay: {
              background: darkMode
                ? "rgba(0, 0, 0, 0.85)"
                : customModalStyles.overlay.background,
              transform: "translateY(150%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>HOW TO PLAY</h2>
        <h3>Guess the product price in 6 tries.</h3>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <div className="game_instructions_container">
          <ul className="how_to_play_list">
            <li>All prices are in USD.</li>
            <li>Guess within 10% of the actual product price to win!</li>
            <li>
              After each of your first three guesses, additional product details
              are revealed.
            </li>
            <li>
              Each guess will also yield feedback:
              <div className="feedback_statements_container">
                <p className="feedback_statement">
                  <span className="symbol_element">⬆️</span> Guess higher next
                  time
                </p>
                <p className="feedback_statement">
                  <span className="symbol_element">⬇️</span> Guess lower next
                  time
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="game_countdown_timer">
          <p>
            Next Apparle in <CountdownTimer />
          </p>
          <div className="time_disclaimer">
            <p>at 12:00 AM Eastern Time (GMT-4)</p>
            {!fullTimezoneDate.includes("12:00 AM") && (
              <>
                <p>or {fullTimezoneDate}</p>
              </>
            )}
          </div>
          <button className="modal_play_button" onClick={handleCloseModal}>
            PLAY
          </button>
        </div>
      </Modal>
    </RemoveScroll>
  );
};
