import { useContext } from "react";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { AppContext } from "../../App";
import { GraphDistribution } from "../Statistics/GraphDistribution";
import { ShareButtons } from "./ShareButtons";
import "../HowToPlay/HowToPlay.scss";
import "./Winner.scss";

const customModalStyles = {
  overlay: {
    transform: "translateY(0%)",
    background: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(2px)",
    zIndex: 99999,
    opacity: 1,
    transition: "transform 0.5s ease",
  },
};

interface GlobalStatsModalProps {
  copiedShareStats: string;
  showGlobalStatsModal: boolean;
  changeShowGlobalStatsModal: React.Dispatch<React.SetStateAction<boolean>>;
  globalStats:
    | {
        [key: string]: number;
      }
    | undefined;
  totalGlobalPlays: number;
}

export const GlobalStatsModal = ({
  copiedShareStats,
  showGlobalStatsModal,
  changeShowGlobalStatsModal,
  globalStats,
  totalGlobalPlays,
}: GlobalStatsModalProps) => {
  const { objectiveCurrentDate, darkMode, productDetails } =
    useContext(AppContext);
  const handleCloseModal = () => {
    changeShowGlobalStatsModal(false);
  };

  Modal.setAppElement("#root");

  return (
    <RemoveScroll enabled={showGlobalStatsModal}>
      <Modal
        isOpen={true}
        onRequestClose={handleCloseModal}
        contentLabel="Global Stats Modal"
        className={`modal_contents all_guesses_modal ${
          showGlobalStatsModal ? "show_modal" : ""
        } ${darkMode ? "dark" : ""}`}
        shouldFocusAfterRender={false}
        style={{
          ...customModalStyles,
          ...(darkMode && {
            overlay: {
              ...customModalStyles.overlay,
              background: "rgba(0, 0, 0, 0.85)",
            },
          }),
          ...(!showGlobalStatsModal && {
            overlay: {
              transform: "translateY(150%)",
              transition: "transform 0.5s ease",
              zIndex: 99999,
            },
          }),
        }}
      >
        <h2>
          {productDetails?.weekStarting
            ? `WEEKLY 50 #${Number(productDetails?.index) + 1}`
            : "TODAY'S"}{" "}
          GLOBAL STATS
        </h2>
        <h3>
          {productDetails?.weekStarting
            ? `Week starting ${productDetails?.weekStarting}`
            : `Apparle #${
                productDetails?.gameNum || 1
              } - ${objectiveCurrentDate}`}
        </h3>
        <h3 className="total_plays_modal_statement">
          Total plays: <b>{totalGlobalPlays}</b>
        </h3>
        <button className="close_modal_button" onClick={handleCloseModal}>
          <RxCross2 size={25} color={darkMode ? "#fff" : "#000"} />
        </button>
        <GraphDistribution globalStats={globalStats} />
        <ShareButtons copiedShareStats={copiedShareStats} />
      </Modal>
    </RemoveScroll>
  );
};
